import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LayoutFactory, PageLayoutType } from '../../layout/LayoutFactory';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authTokenState, roleAccessState } from '../../../atoms/auth/auth.atom';
import { routes } from '../../../config/routes/routes';
import LazyLoad from './LazyLoading/LazyLoading';
import NotFoundPage from '../../pages/shared/error/NotFoundPage/NotFoundPage';
import { useMutation } from '@tanstack/react-query';
import axios, { SuccessResponse } from '../../../libraries/axios/axios';
import { MappedRoleAccess } from '../../../domain/Role';
import { DefaultError } from '@tanstack/query-core/build/modern/index';
import { apiRoutes } from '../../../config/api/apiRoutes';

/* eslint-disable new-cap */
const Dashboard = LazyLoad(
  () => import('../../pages/shared/admin/DashboardPage/DashboardPage'),
);
const UsersPage = LazyLoad(
  () => import('../../pages/shared/admin/User/UsersPage/UsersPage'),
);
const UserCreatePage = LazyLoad(
  () => import('../../pages/shared/admin/User/UserCreatePage/UserCreatePage'),
);
const UserEditPage = LazyLoad(
  () => import('../../pages/shared/admin/User/UserEditPage/UserEditPage'),
);
const TranslationsPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/admin/Translation/TranslationsPage/TranslationsPage'
    ),
);
const ProfilePage = LazyLoad(
  () => import('../../pages/shared/admin/Profile/ProfilePage/ProfilePage'),
);
const SettingsPage = LazyLoad(
  () => import('../../pages/shared/admin/Settings/SettingsPage/SettingsPage'),
);

const LoginPage = LazyLoad(
  () => import('../../pages/shared/public/LoginPage/LoginPage'),
);
const PasswordResetPage = LazyLoad(
  () => import('../../pages/shared/public/PasswordResetPage/PasswordResetPage'),
);
const PasswordResetConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/PasswordResetConfirmationPage/PasswordResetConfirmationPage'
    ),
);
const RegistrationConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);
const DeletionConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/DeletionConfirmationPage/DeletionConfirmationPage'
    ),
);

const ClientsPage = LazyLoad(
  () => import('../../pages/custom/admin/Client/ClientsPage/ClientsPage'),
);
const ClientCreatePage = LazyLoad(
  () =>
    import('../../pages/custom/admin/Client/ClientCreatePage/ClientCreatePage'),
);
const ClientEditPage = LazyLoad(
  () => import('../../pages/custom/admin/Client/ClientEditPage/ClientEditPage'),
);

const ClientRepresentativeCreatePage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/ClientRepresentative/ClientRepresentativeCreatePage/ClientRepresentativeCreatePage'
    ),
);
const ClientRepresentativeEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/ClientRepresentative/ClientRepresentativeEditPage/ClientRepresentativeEditPage'
    ),
);

const ProjectsPage = LazyLoad(
  () => import('../../pages/custom/admin/Project/ProjectsPage/ProjectsPage'),
);
const ProjectCreatePage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Project/ProjectCreatePage/ProjectCreatePage'
    ),
);
const ProjectEditPage = LazyLoad(
  () =>
    import('../../pages/custom/admin/Project/ProjectEditPage/ProjectEditPage'),
);

const EmployeesPage = LazyLoad(
  () => import('../../pages/custom/admin/Employee/EmployeesPage/EmployeesPage'),
);
const EmployeeCreatePage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Employee/EmployeeCreatePage/EmployeeCreatePage'
    ),
);
const EmployeeEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Employee/EmployeeEditPage/EmployeeEditPage'
    ),
);

const TasksPage = LazyLoad(
  () => import('../../pages/custom/admin/Task/TasksPage/TasksPage'),
);
const TaskPage = LazyLoad(
  () => import('../../pages/custom/admin/Task/TaskPage/TaskPage'),
);

const InvoicesPage = LazyLoad(
  () => import('../../pages/custom/admin/Invoice/InvoicesPage/InvoicesPage'),
);
const InvoiceEditPage = LazyLoad(
  () =>
    import('../../pages/custom/admin/Invoice/InvoiceEditPage/InvoiceEditPage'),
);
/* eslint-enable new-cap */

export const RouteProvider = () => {
  const authStateValue = useRecoilValue(authTokenState);
  const setRoleAccessStateValue = useSetRecoilState(roleAccessState);

  const { mutate: getMappedRoleAccess } = useMutation<
    SuccessResponse<MappedRoleAccess[]>,
    DefaultError
  >({
    mutationFn: () => axios.get(apiRoutes.roles.mappedAccess),
    onSuccess: (response) => {
      setRoleAccessStateValue(response.data);
    },
  });

  useEffect(() => {
    if (authStateValue) {
      getMappedRoleAccess();
    }
  }, [authStateValue]);

  const adminPages = useMemo(() => {
    if (authStateValue) {
      return (
        <>
          <Route path={routes.admin.dashboard} element={<Dashboard />} />
          <Route path={routes.homepage} element={<Dashboard />} />
          <Route path={routes.admin.users.list} element={<UsersPage />} />
          <Route path={routes.admin.users.new} element={<UserCreatePage />} />
          <Route path={routes.admin.users.edit} element={<UserEditPage />} />
          <Route path={routes.admin.profile} element={<ProfilePage />} />
          <Route path={routes.admin.settings} element={<SettingsPage />} />
          <Route
            path={routes.admin.translations.list}
            element={<TranslationsPage />}
          />
          <Route path={routes.admin.clients.list} element={<ClientsPage />} />
          <Route
            path={routes.admin.clients.new}
            element={<ClientCreatePage />}
          />
          <Route
            path={routes.admin.clients.edit}
            element={<ClientEditPage />}
          />
          <Route
            path={routes.admin.clientRepresentatives.new}
            element={<ClientRepresentativeCreatePage />}
          />
          <Route
            path={routes.admin.clientRepresentatives.edit}
            element={<ClientRepresentativeEditPage />}
          />
          <Route path={routes.admin.projects.list} element={<ProjectsPage />} />
          <Route
            path={routes.admin.projects.new}
            element={<ProjectCreatePage />}
          />
          <Route
            path={routes.admin.projects.edit}
            element={<ProjectEditPage />}
          />
          <Route
            path={routes.admin.employees.list}
            element={<EmployeesPage />}
          />
          <Route
            path={routes.admin.employees.new}
            element={<EmployeeCreatePage />}
          />
          <Route
            path={routes.admin.employees.edit}
            element={<EmployeeEditPage />}
          />
          <Route path={routes.admin.tasks.list} element={<TasksPage />} />
          <Route path={routes.admin.tasks.details} element={<TaskPage />} />
          <Route path={routes.admin.invoices.list} element={<InvoicesPage />} />
          <Route
            path={routes.admin.invoices.edit}
            element={<InvoiceEditPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </>
      );
    }

    return <></>;
  }, [authStateValue]);

  const authPages = useMemo(() => {
    if (authStateValue) {
      return (
        <>
          <Route
            path={routes.auth.registrationConfirmation}
            element={<RegistrationConfirmationPage />}
          />
          <Route
            path={routes.auth.deletionConfirmation}
            element={<DeletionConfirmationPage />}
          />
        </>
      );
    }

    return (
      <>
        <Route
          path={routes.auth.registrationConfirmation}
          element={<RegistrationConfirmationPage />}
        />
        <Route
          path={routes.auth.deletionConfirmation}
          element={<DeletionConfirmationPage />}
        />
        <Route
          path={routes.auth.passwordReset}
          element={<PasswordResetPage />}
        />
        <Route
          path={routes.auth.passwordResetConfirmation}
          element={<PasswordResetConfirmationPage />}
        />
        <Route path={routes.auth.login} element={<LoginPage />} />
        <Route path={routes.homepage} element={<LoginPage />} />
        <Route path="*" element={<Navigate to={routes.auth.login} replace />} />
      </>
    );
  }, [authStateValue]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutFactory variant={PageLayoutType.admin} />}>
          {adminPages}
        </Route>
        <Route element={<LayoutFactory variant={PageLayoutType.auth} />}>
          {authPages}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteProvider;
